@import '~ethos-design-system/src/components/Media/Media.scss';

// See comment on HeaderSemiversatile.module.scss about responsive spacers.

.desktopSpacer {
  height: var(--Space-16);
  @include for-desktop-only {
    height: var(--Space-24);
  }
}

// Homepage only
.Header__WrapperForest {
  background-color: var(--BrandForest);
  color: var(--White);
}

@include for-phone-only {
  .Header__Column {
    max-width: 480px;
    margin: 0 auto;
  }
  .Header__ColumnText {
    padding: 0 var(--Space-24);
    > a {
      text-align: center;
    }
  }
  .Header__ColumnImage {
    width: 100%;
    height: 468px;
    .Header__PhoneBg {
      width: 100%;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      height: 100px;
      background: var(--BrandForest);
    }
    figure,
    .Header__ImageBg {
      width: calc(100% - var(--Space-24));
      height: 420px;
    }
    .Header__ImageBg {
      left: 0;
      bottom: 0;
    }
    figure {
      right: 0;
      bottom: var(--Space-48);
      img {
        position: absolute;
        bottom: 0;
        width: 456px;
        height: 420px;
        object-fit: cover;
      }
    }
  }
}
@media (max-width: 495px) {
  .Header__ColumnImage {
    height: 399px;
    background: var(--White);
    figure,
    .Header__ImageBg {
      height: 351px;
    }
    figure {
      bottom: var(--Space-48);
    }
    .Header__ImageBg {
      bottom: 0;
    }
  }
}
.Header__Inner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: auto;
  padding-top: var(--Space-48);
  .Header__ColumnImage {
    position: relative;
    z-index: 0;
    overflow: hidden;
    display: block;
    flex-basis: auto;
    figure,
    .Header__ImageBg {
      position: absolute;
    }
    .Header__ImageBg {
      background: var(--BrandMoss);
    }
  }
  @include for-tablet-only {
    .Header__ColumnText {
      max-width: 368px;
      padding-right: var(--Space-48);
    }
    .Header__ColumnImage {
      flex-grow: 1;
      height: 686px;
      min-width: 184px;
      figure,
      .Header__ImageBg {
        width: calc(100% - var(--Space-32));
        max-width: 386px;
        height: 656px;
      }
      .Header__ImageBg {
        right: var(--Space-32);
        bottom: 0;
      }
      figure {
        right: 0;
        bottom: 30px;
        max-width: 386px;
        overflow: hidden;
        img {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 656px;
          width: 386px;
          object-fit: cover;
        }
      }
    }
  }
  @include for-tablet-and-up {
    padding-left: var(--Space-48);
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    max-width: 1440px;
    align-items: center;
    .Header__SubHeading {
      padding-bottom: var(--Space-8);
    }
    .Header__ColumnText {
      min-width: 320px;
    }
  }
  @include for-laptop-only {
    padding-right: var(--Space-48);
    .Header__ColumnText {
      max-width: 448px;
      padding-right: var(--Space-64);
    }
    .Header__ColumnImage {
      flex-grow: 1;
      height: 686px;
      min-width: 504px;
      figure,
      .Header__ImageBg {
        width: calc(100% - var(--Space-32));
        max-width: 504px;
        height: 656px;
      }
      .Header__ImageBg {
        right: var(--Space-32);
        bottom: 0;
      }
      figure {
        right: 0;
        bottom: 30px;
        max-width: 504px;
        overflow: hidden;
        img {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 656px;
          width: 504px;
          object-fit: cover;
        }
      }
    }
  }

  @include for-desktop-only {
    padding-right: var(--Space-80);
    padding-left: var(--Space-80);

    .Header__ColumnText {
      padding-right: 44px;
      max-width: 528px;
    }

    .Header__ColumnImage {
      flex-grow: 1;
      height: 776px;
      min-width: 520px;
      figure,
      .Header__ImageBg {
        width: calc(100% - var(--Space-48));
        max-width: 592px;
        height: 732px;
      }
      .Header__ImageBg {
        right: var(--Space-48);
        bottom: 0;
      }
      figure {
        right: 0;
        bottom: 44px;
        max-width: 592px;
        overflow: hidden;
        img {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 732px;
          width: 592px;
          object-fit: cover;
        }
      }
    }
  }
}
