@import '~ethos-design-system/src/components/Media/Media.scss';

.image {
  vertical-align: middle;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.bylineGroup {
  a {
    text-decoration: none;
  }
}

.previewBylineGroup {
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
}
