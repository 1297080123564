@import '~ethos-design-system/src/components/Media/Media.scss';

@include for-phone-only {
  .TabletAndUp {
    display: none;
  }
}

@include for-tablet-and-up {
  .PhoneOnly {
    display: none;
  }
}

.Header__Wrapper {
  .Header {
    padding: 0 var(--Space-24);

    @include for-laptop-only {
      padding: 0 var(--Space-64);
    }

    @include for-desktop-only {
      padding: 0 var(--Space-80);
    }

    &.HeaderHome {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .Header__Column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

    &.hideMediaMobile {
      @include for-phone-only {
        display: none;
      }
    }

    @include for-laptop-and-up {
      padding: 0;
      flex: 0 1 49%;
      justify-content: center;
    }
  }
}
