@import '~ethos-design-system/src/components/Media/Media.scss';

.Header__Column {
  padding-bottom: var(--Space-56);
}

@include for-laptop-and-up {
  .Header__Column:not(.NoMedia) {
    padding-bottom: 0;
  }
  .Header__Column.NoMedia {
    padding-bottom: var(--Space-32);
  }
}

.Header__Inner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: auto;

  @include for-tablet-and-up {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    max-width: 1280px;
    align-items: center;
    .Header__SubHeading {
      padding-bottom: var(--Space-8);
    }
    .Header__ColumnText {
      min-width: 280px;
      margin-right: var(--Space-56);
      word-break: keep-all;
      padding-top: var(--Space-48);
      padding-bottom: var(--Space-48);
    }
    &.HeaderVersatile__Video {
      padding-top: var(--Space-56);
      padding-bottom: var(--Space-56);
    }
    &.HeaderVersatile__Image {
      .Header__Column:last-child {
        padding-bottom: 0;
        overflow: hidden;
      }
    }
  }
  @include for-tablet-only {
    .Header__ColumnText {
      max-width: 280px;
    }
    margin-right: var(--Space-56);
    .Header__Column:last-child {
      flex: 1 1 384px;
      max-width: 384px;
      max-height: 476px;
    }
  }
  @include for-laptop-only {
    .Header__ColumnText {
      max-width: 400px;
      margin-right: var(--Space-80);
      .Header__Column:last-child {
        flex: 1 0 512px;
        max-width: 512px;
        max-height: 368px;
      }
    }
  }
  @include for-desktop-only {
    .Header__ColumnText {
      max-width: 520px;
      margin-right: 120px;
      .Header__Column:last-child {
        flex: 1 0 720px;
        max-width: 720px;
        max-height: 480px;
      }
    }
  }
}

.Header.HeaderVersatile {
  @include for-phone-only {
    padding-left: 0;
    padding-right: 0;
  }
  .Header__ColumnText {
    padding: var(--Space-48) var(--Space-16);
    @include for-desktop-only {
      padding-left: 0;
    }
  }
  @include for-tablet-and-up {
    padding-right: 0;
  }
  @include for-desktop-only {
    max-width: var(--ContainerMaxWidth);
    margin: 0 auto;
    padding-left: var(--ContainerPadding--Desktop);
    padding-right: var(--ContainerPadding--Desktop);
  }
}

.ctas {
  display: flex;
  > :first-child {
    margin-right: var(--Space-16);
  }
  @include for-tablet-only {
    flex-direction: column;
    > :first-child {
      margin-bottom: var(--Space-24);
    }
  }
  @include for-tablet-only {
    > :first-child {
      margin-right: 0;
    }
  }
  &.center {
    justify-content: center;
    @include for-tablet-and-up {
      justify-content: flex-start;
    }
  }
}

.zipcodeFieldLabel {
  color: var(--GraySecondary--opaque);
  font-size: var(--Space-16);
}

.zip {
  display: flex;

  button {
    border-radius: 0;
  }

  input {
    width: 100%;
  }

  .zipInput {
    max-width: 200px;

    @include for-phone-only {
      max-width: 50%;
    }

    @include for-tablet-only {
      width: 88px;
    }
  }

  .zipSubmit {
    padding-top: var(--Space-4);
  }
}
