@import '~ethos-design-system/src/components/Media/Media.scss';

.Header__Column {
  @include for-laptop-only {
    padding-top: var(--Space-16);
    padding-bottom: var(--Space-24);
  }
  @include for-desktop-only {
    padding-top: var(--Space-32);
    padding-bottom: 96px;
  }
}
