@import '~ethos-design-system/src/components/Media/Media.scss';

// These spacer elements were added to replace responsive global CSS during the
// 2019-10 CSS modules transition. It would probably be more performant and a
// better developer experience to add support for margin-bottom in EDS Type,
// and/or make a responsive <Spacer> component.

.footnoteSpacer {
  height: var(--Space-4);
  @include for-tablet-and-up {
    height: var(--Space-8);
  }
}

.titleSpacer {
  @include for-tablet-and-up {
    height: var(--Space-8);
  }
}

.HeaderSemiVersatile {
  text-align: center;
}

.Header__Inner {
  margin: 0 auto;
  padding-top: var(--Space-40);
  padding-bottom: 52px;

  @include for-tablet-and-laptop {
    padding-top: var(--Space-64);
    padding-bottom: var(--Space-72);
    max-width: 640px;
  }

  @include for-desktop-only {
    padding-top: var(--Space-80);
    padding-bottom: 88px;
    max-width: 720px;
  }
}
